.container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  max-height: min(100%, 100vh);
  overflow: hidden;
  overscroll-behavior: none;
}

.videoHeader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 1;
  margin: 0px;
  padding: 0px;
}

.videoFooter {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
}

.buttons {
  display: flex;
  justify-content: stretch;
  margin-top: 40px;
}

.actionButton {
  z-index: 2;
  height: 50px;
  width: 20%;
}