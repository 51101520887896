audio, video { position: relative; z-index: -1; }

.localVideo {
  position: relative;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  margin: auto auto;
  object-fit: cover;
  transition: 0.3s all ease-in-out;
}

.localVideo.invert {
  filter: invert();
}

.localVideo.softwareZoom {
  /* display: none; */
}

.softwareZoomCanvas {
  position: relative;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  margin: auto auto;
  object-fit: cover;
  display: none;
  box-sizing: border-box;
}

.softwareZoomCanvas.invert {
  filter: invert();
}

.softwareZoomCanvas.softwareZoom {
  /* display: flex; */
}

.debug {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  background: white;
  display: none;
}