.header {
  display: flex;
  flex-direction: row;
  background-color: #2b3e4f;
  padding: 20px 50px;
  font-size: 26px;
  color: white;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
}

.textOnline {
  color: #5f738b;
}

.textBeta {
  color: #555555;
  font-size: 16px;
  position: relative;
  top: 5px;
}

.header svg {
  width: 40px !important;
  height: 40px !important;
  fill: #7fb9bd !important;
  margin-right: 10px;
}
