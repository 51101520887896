.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  max-height: min(100%, 100vh);
  overflow: hidden;
  overscroll-behavior: none;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: #e06663;
  height: auto;
  min-height: 40%;
  color: white;
  line-height: 200%;
  padding: 50px;
  font-size: 26px;
  font-variant: small-caps;
  font-weight: 600;
  flex-grow: 1;
  overflow-y: auto;
}

.mainButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #49c9b0;
  padding: 40px;
}

.button {
  display: flex;
  border: 3px solid #e06663;
  border-radius: 5px;
  align-items: center;
  height: 50px;
  min-width: 150px;
  text-transform: uppercase;
  padding: 0px 20px;
  color: white;
  font-weight: 800;
  cursor: pointer;
  margin: 20px 10px;
  text-decoration: none;
}

.button svg {
  fill: black;
  margin-right: 10px;
}

.pageTitle {
  font-size: 40px;
  margin-bottom: 20px;
}

.homeButton {
  display: flex;
  border: 3px solid #49c9b0;
  border-radius: 5px;
  align-items: center;
  height: 50px;
  width: 150px;
  text-transform: uppercase;
  padding: 0px 20px;
  color: white;
  font-weight: 800;
  cursor: pointer;
  margin: 40px 0px;
}

.homeButton svg {
  fill: black;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 20px 30px;
    font-size: 20px;
  }

  .textBeta {
    font-size: 12px;
    top: 4px;
  }

  .content {
    padding: 30px;
    font-size: 20px;
  }

  .mainButtons {
    padding: 30px;
  }

  .button {
    height: 35px;
    min-width: 120px;
    padding: 0px 10px;
    margin: 10px 5px;
  }

  .homeButton {
    height: 35px;
    min-width: 120px;
    padding: 0px 10px;
    margin: 30px 5px;
  }
}